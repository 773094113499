
@keyframes customSlideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes customSlideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes customFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.customSlideInRight {
  animation: customSlideInRight 0.5s ease-in-out forwards;
}

.customSlideInLeft {
  animation: customSlideInLeft 0.5s ease-in-out forwards;
}

.customFadeOut {
  animation: customFadeOut 0.5s ease-out forwards;
}
