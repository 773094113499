@import '../abstracts/mixins';
$gauge: "data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='gauge' class='svg-inline--fa fa-gauge ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM280 292.7V88C280 74.75 269.3 64 256 64C242.7 64 232 74.75 232 88V292.7C208.5 302.1 192 325.1 192 352C192 387.3 220.7 416 256 416C291.3 416 320 387.3 320 352C320 325.1 303.5 302.1 280 292.7zM144 176C161.7 176 176 161.7 176 144C176 126.3 161.7 112 144 112C126.3 112 112 126.3 112 144C112 161.7 126.3 176 144 176zM96 224C78.33 224 64 238.3 64 256C64 273.7 78.33 288 96 288C113.7 288 128 273.7 128 256C128 238.3 113.7 224 96 224zM416 288C433.7 288 448 273.7 448 256C448 238.3 433.7 224 416 224C398.3 224 384 238.3 384 256C384 273.7 398.3 288 416 288zM368 112C350.3 112 336 126.3 336 144C336 161.7 350.3 176 368 176C385.7 176 400 161.7 400 144C400 126.3 385.7 112 368 112z'%3E%3C/path%3E%3C/svg%3E";
$tune: "data:image/svg+xml,%3Csvg id='tune' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath id='Path_31' data-name='Path 31' d='M0,0H24V24H0Z' fill='none'/%3E%3Cpath id='Path_32' data-name='Path 32' d='M3,17v2H9V17ZM3,5V7H13V5ZM13,21V19h8V17H13V15H11v6ZM7,9v2H3v2H7v2H9V9Zm14,4V11H11v2ZM15,9h2V7h4V5H17V3H15Z' fill='%23363636'/%3E%3C/svg%3E%0A";
$dashboard: "data:image/svg+xml,%3Csvg id='dashboard_black_24dp' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath id='Path_41' data-name='Path 41' d='M0,0H24V24H0Z' fill='none'/%3E%3Cpath id='Path_42' data-name='Path 42' d='M3,13h8V3H3Zm0,8h8V15H3Zm10,0h8V11H13ZM13,3V9h8V3Z' fill='%23363636'/%3E%3C/svg%3E%0A";
$requisition: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='move_down_black_24dp' transform='translate(24 24) rotate(180)'%3E%3Cg id='Group_14' data-name='Group 14'%3E%3Crect id='Rectangle_16' data-name='Rectangle 16' width='24' height='24' fill='none'/%3E%3C/g%3E%3Cg id='Group_16' data-name='Group 16'%3E%3Cg id='Group_15' data-name='Group 15'%3E%3Cpath id='Path_10' data-name='Path 10' d='M3,11a5,5,0,0,0,4.08,4.91L5.59,14.42,7,13l4,4.01L7,21,5.59,19.59l1.58-1.58v-.06A7,7,0,0,1,8,4h3V6H8A5,5,0,0,0,3,11Z' fill='%23363636'/%3E%3Cpath id='Path_11' data-name='Path 11' d='M22,11V4H13v7ZM20,9H15V6h5Z' fill='%23363636'/%3E%3Crect id='Rectangle_17' data-name='Rectangle 17' width='9' height='7' transform='translate(13 13)' fill='%23363636'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A";
$work: "data:image/svg+xml,%3Csvg id='work_outline' data-name='work outline' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath id='Path_43' data-name='Path 43' d='M0,0H24V24H0Z' fill='none'/%3E%3Cpath id='Path_44' data-name='Path 44' d='M14,6V4H10V6ZM4,8V19H20V8ZM20,6a1.993,1.993,0,0,1,2,2V19a1.993,1.993,0,0,1-2,2H4a1.993,1.993,0,0,1-2-2L2.01,8A1.985,1.985,0,0,1,4,6H8V4a1.993,1.993,0,0,1,2-2h4a1.993,1.993,0,0,1,2,2V6Z' fill='%23363636'/%3E%3C/svg%3E%0A";
$notifications-dark: "data:image/svg+xml,%3Csvg id='notifications' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath id='Path_3566' data-name='Path 3566' d='M0,0H32V32H0Z' fill='none'/%3E%3Cpath id='Path_3567' data-name='Path 3567' d='M14.544,28.362a2.652,2.652,0,0,0,2.636-2.653H11.908A2.652,2.652,0,0,0,14.544,28.362ZM22.453,20.4V13.773c0-4.072-2.148-7.48-5.931-8.382v-.9a1.977,1.977,0,1,0-3.954,0v.9c-3.77.9-5.931,4.3-5.931,8.382V20.4L4,23.057v1.326H25.089V23.057Zm-2.636,1.326H9.272V13.773c0-3.289,1.99-5.968,5.272-5.968s5.272,2.679,5.272,5.968Z' transform='translate(1.456 0.91)'/%3E%3C/svg%3E%0A";
$notifications: "data:image/svg+xml,%3Csvg id='notifications_white' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath id='Path_225' data-name='Path 225' d='M0,0H38V38H0Z' fill='none'/%3E%3Cpath id='Path_226' data-name='Path 226' d='M16.667,33.375a3.176,3.176,0,0,0,3.167-3.167H13.5A3.176,3.176,0,0,0,16.667,33.375Zm9.5-9.5V15.958c0-4.861-2.581-8.93-7.125-10.007V4.875a2.375,2.375,0,0,0-4.75,0V5.952c-4.528,1.077-7.125,5.13-7.125,10.007v7.917L4,27.042v1.583H29.333V27.042ZM23,25.458H10.333v-9.5c0-3.927,2.391-7.125,6.333-7.125S23,12.032,23,15.958Z' transform='translate(2.333 1.458)' fill='%23fff'/%3E%3C/svg%3E%0A";
$user: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z'/%3E%3C/svg%3E";
$wifi-dark: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='32' height='32' viewBox='0 0 32 32'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_203' data-name='Rectangle 203' width='30' height='21.208' transform='translate(0 0)' fill='%23339933'/%3E%3C/clipPath%3E%3CclipPath id='clip-Artboard_2'%3E%3Crect width='32' height='32'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Artboard_2' data-name='Artboard – 2' clip-path='url(%23clip-Artboard_2)'%3E%3Cg id='Group_475' data-name='Group 475' transform='translate(1 5)'%3E%3Cg id='Group_474' data-name='Group 474' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_216' data-name='Path 216' d='M0,6.208,2.727,8.935a17.359,17.359,0,0,1,24.546,0L30,6.208a21.226,21.226,0,0,0-30,0' fill='%23339933'/%3E%3Cpath id='Path_217' data-name='Path 217' d='M10.909,17.117,15,21.208l4.091-4.091a5.777,5.777,0,0,0-8.182,0' fill='%23339933'/%3E%3Cpath id='Path_218' data-name='Path 218' d='M5.455,11.662l2.726,2.727a9.649,9.649,0,0,1,13.638,0l2.726-2.727a13.512,13.512,0,0,0-19.09,0' fill='%23339933'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A";
$nowifi-dark: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='32' height='32' viewBox='0 0 32 32'%3E%3Cdefs%3E%3CclipPath id='clip-Artboard_3'%3E%3Crect width='32' height='32'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Artboard_3' data-name='Artboard – 3' clip-path='url(%23clip-Artboard_3)'%3E%3Cg id='wifi_off_black_24dp' transform='translate(1 1)'%3E%3Cpath id='Path_219' data-name='Path 219' d='M0,0H30V30H0Z' fill='none'/%3E%3Cpath id='Path_220' data-name='Path 220' d='M26,13.34l2.5-2.5A19.422,19.422,0,0,0,11.375,5.453L14.6,8.677A15.933,15.933,0,0,1,26,13.34Zm-2.5,2.5a12.369,12.369,0,0,0-4.65-2.913L22.625,16.7l.875-.862Zm-12.5,5,3.75,3.75,3.75-3.75A5.3,5.3,0,0,0,11,20.84ZM4.013,1.64,2.25,3.4,6.063,7.215A19.628,19.628,0,0,0,1,10.84l2.5,2.5A15.811,15.811,0,0,1,8.712,9.865l2.8,2.8A12.279,12.279,0,0,0,6,15.84l2.5,2.5a8.748,8.748,0,0,1,6.112-2.575l8.85,8.85,1.762-1.763Z' transform='translate(0.25 0.41)' fill='%23CC0000'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A";
$wifi-light: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='32' height='32' viewBox='0 0 32 32'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_203' data-name='Rectangle 203' width='30' height='21.208' transform='translate(0 0)' fill='%23339933'/%3E%3C/clipPath%3E%3CclipPath id='clip-Artboard_2'%3E%3Crect width='32' height='32'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Artboard_2' data-name='Artboard – 2' clip-path='url(%23clip-Artboard_2)'%3E%3Cg id='Group_475' data-name='Group 475' transform='translate(1 5)'%3E%3Cg id='Group_474' data-name='Group 474' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_216' data-name='Path 216' d='M0,6.208,2.727,8.935a17.359,17.359,0,0,1,24.546,0L30,6.208a21.226,21.226,0,0,0-30,0' fill='%23339933'/%3E%3Cpath id='Path_217' data-name='Path 217' d='M10.909,17.117,15,21.208l4.091-4.091a5.777,5.777,0,0,0-8.182,0' fill='%23339933'/%3E%3Cpath id='Path_218' data-name='Path 218' d='M5.455,11.662l2.726,2.727a9.649,9.649,0,0,1,13.638,0l2.726-2.727a13.512,13.512,0,0,0-19.09,0' fill='%23339933'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A";
$nowifi-light: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='32' height='32' viewBox='0 0 32 32'%3E%3Cdefs%3E%3CclipPath id='clip-Artboard_3'%3E%3Crect width='32' height='32'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Artboard_3' data-name='Artboard – 3' clip-path='url(%23clip-Artboard_3)'%3E%3Cg id='wifi_off_black_24dp' transform='translate(1 1)'%3E%3Cpath id='Path_219' data-name='Path 219' d='M0,0H30V30H0Z' fill='none'/%3E%3Cpath id='Path_220' data-name='Path 220' d='M26,13.34l2.5-2.5A19.422,19.422,0,0,0,11.375,5.453L14.6,8.677A15.933,15.933,0,0,1,26,13.34Zm-2.5,2.5a12.369,12.369,0,0,0-4.65-2.913L22.625,16.7l.875-.862Zm-12.5,5,3.75,3.75,3.75-3.75A5.3,5.3,0,0,0,11,20.84ZM4.013,1.64,2.25,3.4,6.063,7.215A19.628,19.628,0,0,0,1,10.84l2.5,2.5A15.811,15.811,0,0,1,8.712,9.865l2.8,2.8A12.279,12.279,0,0,0,6,15.84l2.5,2.5a8.748,8.748,0,0,1,6.112-2.575l8.85,8.85,1.762-1.763Z' transform='translate(0.25 0.41)' fill='%23CC0000'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A";
$arrowLeft: "data:image/svg+xml;charset=utf8,%3Csvg id='arrow_back_black_24dp_1_' data-name='arrow_back_black_24dp (1)' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath id='Path_247' data-name='Path 247' d='M0,0H24V24H0Z' fill='none'/%3E%3Cpath id='Path_248' data-name='Path 248' d='M20,11H7.83l5.59-5.59L12,4,4,12l8,8,1.41-1.41L7.83,13H20Z'/%3E%3C/svg%3E";
$avatar-dark: "data:image/svg+xml;charset=utf8,%3Csvg id='arrow_back_black_24dp_1_' data-name='arrow_back_black_24dp (1)' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath id='Path_247' data-name='Path 247' d='M0,0H24V24H0Z' fill='none'/%3E%3Cpath id='Path_248' data-name='Path 248' d='M20,11H7.83l5.59-5.59L12,4,4,12l8,8,1.41-1.41L7.83,13H20Z'/%3E%3C/svg%3E";
$avatar-light: "data:image/svg+xml;charset=utf8,%3Csvg id='arrow_back_black_24dp_1_' data-name='arrow_back_black_24dp (1)' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath id='Path_247' data-name='Path 247' d='M0,0H24V24H0Z' fill='none'/%3E%3Cpath id='Path_248' data-name='Path 248' d='M20,11H7.83l5.59-5.59L12,4,4,12l8,8,1.41-1.41L7.83,13H20Z'/%3E%3C/svg%3E";
$logo-dark: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='43.2' height='41.573' viewBox='0 0 43.2 41.573'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Cpath id='Path_227' data-name='Path 227' d='M29.28,0l-.033,7.433a2.2,2.2,0,0,1,.623.323,15.264,15.264,0,0,1,.114,25.582c-.2.132-.744.459-.744.459V20.656L21.6,24.216l-7.641-3.56V33.8s-.543-.327-.744-.459A15.264,15.264,0,0,1,13.33,7.755a2.2,2.2,0,0,1,.623-.323L13.92,0a5.135,5.135,0,0,0-.554.163C1.754,5.236.007,14.872,0,20.222v.068A21.644,21.644,0,0,0,.3,24,21.8,21.8,0,0,0,18.077,41.572V27.412l3.523,2.4,3.523-2.4v14.16A21.8,21.8,0,0,0,42.9,24a21.657,21.657,0,0,0,.3-3.712v-.066C43.193,14.873,41.447,5.236,29.834.162A5.136,5.136,0,0,0,29.28,0Z' transform='translate(0 0.001)' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_568' data-name='Group 568' transform='translate(0 0.001)'%3E%3Cg id='Group_567' data-name='Group 567' transform='translate(0 -0.001)' clip-path='url(%23clip-path)'%3E%3Crect id='Rectangle_230' data-name='Rectangle 230' width='43.2' height='41.573' transform='translate(0 0)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A";
$logo-light: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='43.2' height='41.573' viewBox='0 0 43.2 41.573'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Cpath id='Path_227' data-name='Path 227' d='M29.28,0l-.033,7.433a2.2,2.2,0,0,1,.623.323,15.264,15.264,0,0,1,.114,25.582c-.2.132-.744.459-.744.459V20.656L21.6,24.216l-7.641-3.56V33.8s-.543-.327-.744-.459A15.264,15.264,0,0,1,13.33,7.755a2.2,2.2,0,0,1,.623-.323L13.92,0a5.135,5.135,0,0,0-.554.163C1.754,5.236.007,14.872,0,20.222v.068A21.644,21.644,0,0,0,.3,24,21.8,21.8,0,0,0,18.077,41.572V27.412l3.523,2.4,3.523-2.4v14.16A21.8,21.8,0,0,0,42.9,24a21.657,21.657,0,0,0,.3-3.712v-.066C43.193,14.873,41.447,5.236,29.834.162A5.136,5.136,0,0,0,29.28,0Z' transform='translate(0 0.001)' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_568' data-name='Group 568' transform='translate(0 0.001)'%3E%3Cg id='Group_567' data-name='Group 567' transform='translate(0 -0.001)' clip-path='url(%23clip-path)'%3E%3Crect id='Rectangle_230' data-name='Rectangle 230' width='43.2' height='41.573' transform='translate(0 0)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A";
$menu-light: "data:image/svg+xml,%3Csvg id='menu_black_24dp' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath id='Path_223' data-name='Path 223' d='M0,0H24V24H0Z' fill='none'/%3E%3Cpath id='Path_224' data-name='Path 224' d='M3,18H21V16H3Zm0-5H21V11H3ZM3,6V8H21V6Z' fill='%23fff'/%3E%3C/svg%3E%0A";
$new: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' width='18px' height='18px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23ccccc;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M18,2h-8L4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C20,2.9,19.1,2,18,2z M9.8,5v2.8H7L9.8,5z M18,20H6V9.9 h5.8V4H18V20z'/%3E%3Cpath class='st0' d='M15.9,13.9l-1.7-0.2c0,0-0.1,0-0.1-0.1c0,0,0-0.1,0-0.1l0.3-1.7l-1.4,0.8c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1 L12,11l-0.7,1.5c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0l-1.5-0.8l0.3,1.6c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1l-1.6,0.2L9.2,15 c0,0,0.1,0.1,0.1,0.1s0,0.1-0.1,0.1l-1.2,1.2l1.6,0.2c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1l-0.3,1.6l1.5-0.8c0,0,0.1,0,0.1,0 c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0.1l0.7,1.4l0.7-1.4c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0l1.5,0.8l-0.3-1.7c0,0,0-0.1,0-0.1 c0,0,0.1-0.1,0.1-0.1l1.6-0.3l-1.1-1.1c0,0-0.1-0.1-0.1-0.1s0-0.1,0.1-0.1L15.9,13.9z'/%3E%3C/g%3E%3C/svg%3E";
$add: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='18px' viewBox='0 0 24 24' width='18px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z'/%3E%3C/svg%3E";
$hold-op: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='18px' viewBox='0 0 24 24' width='18px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M18 24h-6.55c-1.08 0-2.14-.45-2.89-1.23l-7.3-7.61 2.07-1.83c.62-.55 1.53-.66 2.26-.27L8 14.34V4.79c0-1.38 1.12-2.5 2.5-2.5.17 0 .34.02.51.05.09-1.3 1.17-2.33 2.49-2.33.86 0 1.61.43 2.06 1.09.29-.12.61-.18.94-.18 1.38 0 2.5 1.12 2.5 2.5v.28c.16-.03.33-.05.5-.05 1.38 0 2.5 1.12 2.5 2.5V20c0 2.21-1.79 4-4 4zM4.14 15.28l5.86 6.1c.38.39.9.62 1.44.62H18c1.1 0 2-.9 2-2V6.15c0-.28-.22-.5-.5-.5s-.5.22-.5.5V12h-2V3.42c0-.28-.22-.5-.5-.5s-.5.22-.5.5V12h-2V2.51c0-.28-.22-.5-.5-.5s-.5.22-.5.5V12h-2V4.79c0-.28-.22-.5-.5-.5s-.5.23-.5.5v12.87l-5.35-2.83-.51.45z'/%3E%3C/svg%3E";
$reject: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='18px' viewBox='0 0 24 24' width='18px' fill='%23ffff'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'/%3E%3C/svg%3E";
$ready-for-approval: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' height='18px' viewBox='0 0 24 24' width='18px' fill='%23000000'%3E%3Crect fill='none' height='24' width='24'/%3E%3Cpath d='M18.6,19.5H21v2h-6v-6h2v2.73c1.83-1.47,3-3.71,3-6.23c0-4.07-3.06-7.44-7-7.93V2.05c5.05,0.5,9,4.76,9,9.95 C22,14.99,20.68,17.67,18.6,19.5z M4,12c0-2.52,1.17-4.77,3-6.23V8.5h2v-6H3v2h2.4C3.32,6.33,2,9.01,2,12c0,5.19,3.95,9.45,9,9.95 v-2.02C7.06,19.44,4,16.07,4,12z M16.24,8.11l-5.66,5.66l-2.83-2.83l-1.41,1.41l4.24,4.24l7.07-7.07L16.24,8.11z'/%3E%3C/svg%3E";
@include registerIcon('notifications', 40px, 40px, $notifications);
@include registerIcon('logo-dark', 100%, 100%, $logo-dark);
@include registerIcon('logo-light', 100%, 100%, $logo-light);
@include registerIcon('menu-light', 25px, 25px, $menu-light);
