.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: border-color 0.3s ease;
  height: 100px; /* Adjust the height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.dropzone:hover {
  border-color: #999;
}

.dropzone p {
  color: #f55f54;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #f55f54;
}

.file-name {
  margin-right: 10px; /* Optional: Adjust margin between file name and delete button */
}

.custom-card {
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 10px; /* Optional: Adjust margin for spacing between cards */
  margin-bottom: 10px; /* Optional: Adjust margin for spacing between cards */
}
