.smart-galleries {
  display: flex;
}

.custom-dialog {
  max-width: 80%; // Adjust as needed
  margin: 0 auto;
}

.k-button-solid-error {
  color: white;
}
