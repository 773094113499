.multi-image-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  width: 260px;
  margin-top: 15px;
}

.multi-container {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.multi-container-item {
  width: 250px;
  margin-bottom: 20px;
}
