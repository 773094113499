.smart-gallery {
  padding: 4px;
  margin: 4px;

  .k-grid-norecords-template {
    border: none;
    text-align: center;
  }

  .k-table-row {
    text-align: center;
  }

  .k-card-header {
    padding: 0px 5px;
  }

  .k-card-body {
    padding-inline: 0px;
  }
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 500px;
  overflow: auto;
  text-align: center;
}

.gallery-container::-webkit-scrollbar {
  width: 20px;
}

.gallery-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.gallery-container::-webkit-scrollbar-thumb {
  background-color: #ececec;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.gallery-container::-webkit-scrollbar-thumb:hover {
  background-color: #c4c5c6;
}

.gallery-image-wrapper {
  flex-basis: calc(50% - 10px);
  margin-bottom: 20px;
}

.gallery-image {
  width: 160px; /* Ensure the image takes up the entire width of its container */
  height: 85px; /* Ensure the image takes up the entire height of its container */
  object-fit: cover; /* Ensure the image covers the entire container while maintaining its aspect ratio */
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow to the image wrapper */
}

/* Styles for small screens */
@media (max-width: 768px) {
  .gallery-image-wrapper {
    flex-basis: calc(100% - 10px); /* Display one image per row */
  }
}

.smart-galleries {
  display: flex;
  flex-wrap: wrap;
}

.smart-gallery {
  flex: 1 1 calc(32% - 20px); /* Three galleries per row on larger screens */
  margin: 5px;
}

@media (max-width: 1200px) {
  .smart-gallery {
    flex: 1 1 calc(50% - 20px); /* Two galleries per row on medium screens */
  }
}

@media (max-width: 768px) {
  .smart-gallery {
    flex: 1 1 calc(100% - 20px); /* One gallery per row on small screens */
  }
}

.k-m-25 {
  margin: 25px;
}

.k-dialog-content img {
  max-width: 100%;
  max-height: 300px;
}

.smart-gallery .k-card {
  border: 1px solid #ccc;
  padding: 10px;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-container h6 {
  margin: 0;
}

.selected.gallery-image {
  border: 4px solid #f55f54;
}
