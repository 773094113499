@import '@progress/kendo-font-icons/dist/index.css';
@import './base/base';
@import './base/icon';
@import './abstracts/breakpoints';
@import './common/Slider';
@import './common/Grid';
@import './common/ActionButtons';

html,
body {
  margin: 0;
  background-color: $body-bg-color;
  overscroll-behavior-y: contain;
}

.k-card-title {
  color: $topbar-title-color;
}

.k-form {
  padding: 14px 16px;
}

.page-title,
.k-card-header {
  border-width: 0 0 1px;
  border-style: solid;
  border-color: $component-border;
  padding: 14px 16px;
  color: $topbar-title-color;

  h4 {
    margin: 0;
    font-size: 24px;
    font-family: Arial;
    line-height: 1.25;
    font-weight: 500;
  }

  &.page-button-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px;

    h4 {
      margin-bottom: 0;
    }
  }

  h5 {
    margin-bottom: 0;
  }
}

/* Styling for TopBar Header in mobile/tablet view*/
.header-bold {
  margin: 0;
  font-size: 24px;
  font-family: Arial;
  line-height: 1.25;
  font-weight: 500;
  color: $white-color;
}

.page {
  margin: 10px;
  padding: 0;
  border-color: $component-border;
  color: $component-text;
  background-color: $white-color;
  border-radius: 4px;
  outline: 0;
  box-shadow: unset;
  border-width: 1px;
  border-style: solid;
  box-shadow:
    0.7px 0.7px 0.5px rgba(0, 0, 0, 0.04),
    1.7px 1.7px 1.2px rgba(0, 0, 0, 0.03),
    3.1px 3.1px 2.1px rgba(0, 0, 0, 0.025),
    5.1px 5.1px 3.5px rgba(0, 0, 0, 0.022),
    8.5px 8.5px 5.8px rgba(0, 0, 0, 0.018),
    14.8px 14.8px 10.2px rgba(0, 0, 0, 0.015),
    32px 32px 22px rgba(0, 0, 0, 0.01);

  .card-detail-content {
    .action-buttons-container {
      a {
        text-decoration: none !important;
      }
    }

    .card-title-header {
      display: flex;
      align-items: center;
      padding: 0 10px;
      background: $white-color;
      border-color: rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.87);
      background-color: $white-color;

      .page-title.page-button-title {
        border: 0;
      }

      span {
        &.gp-ico-arrow-left {
          display: inherit;
          color: #354052;
          font-size: 26px;
        }
      }
    }

    .k-card {
      &.Separate-Card {
        box-shadow: none;
        border-top: 1px solid rgba(0, 0, 0, 0.12);

        .k-card-title {
          font-size: 14px;
          font-weight: 700;
          color: $wr-card-title-color;
        }
      }
    }
  }
}

@include media('<=phone') {
  .page {
    margin: 0;
    padding: 0;
  }
}

// consistent styling for button groups
.k-button-group {
  box-shadow: none;
}

.rounded-checkbox {
  &.k-checkbox,
  input[type='checkbox'] {
    border: 2px solid $grey-color;
    border-radius: 50%;
    cursor: pointer;
    vertical-align: middle;

    :checked {
      background-color: $primary;
      border-color: $primary;
      color: $white-color;
    }
  }
}

// icons styles
[class^='gp-ico-'] {
  vertical-align: middle !important;
}

.gp-ico-nowifi:before {
  color: #cc0000;
}

.gp-ico-wifi:before {
  color: #339933;
}

.k-button-text {
  [class^='gp-ico-'] {
    font-size: 18px !important;
  }
}

.k-dialog-actions {
  margin: 0;
  border-width: 1px 0 0;
  border-color: inherit;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 8px 8px;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: inherit;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 8px;
  overflow: hidden;
}

.k-window-titlebar {
  padding: 12px 16px;
  border-color: inherit;
  border-width: 0 0 1px;
}

.card-col {
  &.switchable {
    display: flex;
    justify-content: start;
    justify-items: baseline;

    strong.heading {
      margin-left: 12px;
    }
  }
}

@include media('<l-desktop') {
  .k-tooltip {
    visibility: hidden;
  }
}

.hide-network-icon {
  display: none;
}

.hidden {
  display: none;
}

.k-loading-mask {
  z-index: 10000003;
  background: #00000059;
  position: fixed;
}

.k-loading-image {
  z-index: 10000004;
}

.k-loading-image {
  color: #ef5a21;
}

.k-loading-color {
  background-color: rgb(0 0 0);
  opacity: 0.3;
}

.k-loading-image::before,
.k-loading-image::after {
  border-width: clamp(0.019em, 2px, 2px);
}

.k-button {
  text-transform: none;
}

.k-button.k-button-solid-primary:hover,
.k-button.k-button-solid-primary:focus {
  text-decoration: none;
  outline: 0;
  background: #f55f54;
  color: white;
  border: 1px #f55f54 solid;
}

.container-div .k-button-solid-primary,
.k-actions .k-button-solid-primary {
  background: #3f51b5;
  color: white;
}

.container-div .k-button-solid-primary:hover,
.k-actions .k-button-solid-primary:hover {
  background: #f55f54;
  color: white;
  border: 1px #f55f54 solid;
}

.container-div .reject-button {
  background: #bdbdbdec;
  color: white;
}

.container-div .reject-button:hover {
  background: black;
  color: white;
}

.k-upload-pct {
  display: none;
}

.k-upload-status {
  display: none !important;
}

.k-upload-action {
  margin: 8px;
}

.container-div .card-image {
  height: 150px !important;
  width: 100%;
}

.card-body {
  display: flex;
  align-items: center;
}

.k-switch-thumb {
  border: 1px solid #3f51b5;
}

.k-mr-6 {
  margin-right: 25px;
}

.video-container {
  display: flex;
  justify-content: center;
}

.heading-border {
  border-bottom: 1px solid rgb(52 67 149 / 14%);
}

.sis-ico-notifications::before {
  content: url('data:image/svg+xml,%3Csvg id=%27notifications_white%27 xmlns=%27http://www.w3.org/2000/svg%27 width=%2732%27 height=%2732%27 viewBox=%270 0 32 32%27%3E%3Cpath id=%27Path_225%27 data-name=%27Path 225%27 d=%27M0,0H38V38H0Z%27 fill=%27none%27/%3E%3Cpath id=%27Path_226%27 data-name=%27Path 226%27 d=%27M16.667,33.375a3.176,3.176,0,0,0,3.167-3.167H13.5A3.176,3.176,0,0,0,16.667,33.375Zm9.5-9.5V15.958c0-4.861-2.581-8.93-7.125-10.007V4.875a2.375,2.375,0,0,0-4.75,0V5.952c-4.528,1.077-7.125,5.13-7.125,10.007v7.917L4,27.042v1.583H29.333V27.042ZM23,25.458H10.333v-9.5c0-3.927,2.391-7.125,6.333-7.125S23,12.032,23,15.958Z%27 transform=%27translate%282.333 1.458%29%27 fill=%27%23fff%27/%3E%3C/svg%3E%0A');
  width: 40px;
  height: 40px;
}
