@import '../../../assets/scss/abstracts/variables';
.login-logo {
  width: 500px;
}
.login-logo-mobile {
  width: 300px;
  margin-bottom: 20px;
}
.login-title {
  font-size: 26px;
  color: $dark;
  font-weight: 400;
  text-transform: none;
  letter-spacing: -0.36px;
}
.login-button {
  padding: 5px 80px !important;
  background-color: $gradient-blue-primary-color !important;
  border: 1px solid $gradient-blue-primary-color !important;
  text-transform: none;
  font-size: 16px;
  margin-top: 10px;
}
.login-bg {
  width: 100%;
  height: 100vh;
  //background-image: url('../../../assets/images/bg12.jpeg');
  background-color: #fff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 10px 5px 5px lightgray;
  opacity: 0.09;
}
.bg-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.login-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  .login-rside {
    background: transparent
      linear-gradient(
        270deg,
        $gradient-blue-primary-color 0%,
        $gradient-blue-secondary-color 100%
      );
  }
  .login-fside {
    background-color: $white-color;
  }
  .login-rside,
  .login-fside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.login-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.keyboard-close {
    justify-content: start;
    margin-top: 20px;
  }
  width: 100%;
  .login-logo {
    margin-bottom: 15px;
  }
}
.login-grid-mobile {
  display: grid;
  grid-template-columns: 1fr;
  height: 100vh;
  background-color: $white-color;
}
.login-grid.error {
  .login-title {
    margin-bottom: 0;
    line-height: 1.4;
  }
  .alert-info {
    margin: 0;
  }
}
.k-font-bold {
  font-weight: 700 !important;
}
.k-login-input.k-input-solid {
  border: 1px solid $light-grey !important;
  border-radius: 6px !important;
  background-color: $white-smoke;
  font-size: 16px;
}
.k-login-input.k-invalid.k-input-solid {
  border-color: $error-color !important;
}
.form-container {
  width: 50%;
}
.form-container-mobile {
  width: 70%;
}
.k-w-100 {
  width: 100%;
}
.k-form .my-test .ai-button-primary {
  background-color: $gradient-blue-primary-color;
  color: $light;
}
.ai-button-primary {
  background-color: $gradient-blue-primary-color !important;
  color: $light !important;
}

.ai-button-primary:hover {
  background-color: #f55f54 !important;
}
