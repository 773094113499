.canvas {
  position: absolute;
  max-width: 100%;
}

.stream-video {
  max-width: 100%;
  height: auto;
}

.video-stream-container {
  position: relative;
  max-width: 1000px;
}

.position-absolute {
  position: absolute;
}

.cam-btn{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}