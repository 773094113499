/** Add SVG Icons Styling **/
@mixin icon($width, $height, $svg) {
  &::before {
    content: url($svg);
    width: $width;
    height: $height;
  }
}
/** Register SVG Icons **/
@mixin registerIcon($name, $width, $height, $svg) {
  .gp-ico-#{$name} {
    @include icon($width, $height, $svg);
  }
}

/** Badge Color Mixing **/
@mixin badgeColor($currentStatus, $nextStatus, $currentColor, $nextColor) {
  &.#{$currentStatus}-#{$nextStatus} {
    background: transparent
      linear-gradient(to right, $currentColor 14%, $nextColor 28%);
  }
}
